/* eslint-disable jsx-a11y/alt-text */
// @mui
import { styled } from '@mui/material/styles';
import {Container, Typography } from '@mui/material';
// hooks
import { FaArrowLeft, FaHome } from 'react-icons/fa'; // Import FaHome
import useResponsive from '../hooks/useResponsive';

// components
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      {/* <Helmet>
        <title> Login | Minimal UI </title>
      </Helmet> */}

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
          <img src="/assets/logo prolink.png" width={250} style={{marginLeft: "-7px", marginBottom: "100px"}}/>
            <Typography variant="h4" gutterBottom mb={4}>
              Sign in
            </Typography>
            <LoginForm />
          </StyledContent>
            <div style={{ textAlign: "center", marginTop: "0px", color:"#000" }}>
              <a href="/">
                <FaArrowLeft style={{ marginRight: "8px" }} />
                <FaHome style={{ marginRight: "8px" }} /> {/* Add Home Icon */}
                Go Back to Home
              </a>
              {/* ... other login page content ... */}
            </div>
        </Container>
      </StyledRoot>
      
    </>
  );
}
