/* eslint-disable no-undef */
import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// routes
import { ToastContainer } from 'react-toastify';
import Routers from './routes';
import UserRoutes from './UserRouters';
import './App.css';
 
// theme
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import LoginPage from './pages/LoginPage';
import Home from './pages/HomePages/Home/Home';
import AboutUs from './pages/AboutUs_Management/AboutUs';
import Blog from './pages/HomePages/BlogPages/BLog/Blog';
import BlogDetail from './pages/HomePages/BlogPages/BlogDetail/BlogDetail';
import Achievement from './pages/HomePages/Achievements/Achievement';
import ContactUs from './pages/HomePages/Contact/Contact';
import HelpCentre from './pages/HomePages/HelpCentre/HelpCentre';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './theme';
import UserLogin from './sections/auth/UserLogin/UserLogin';
import UserSignup from './sections/auth/UserLogin/UserSignup/UserSignup';
import HealthInsurance from './pages/HomePages/Insurance/HealthInsurance/HealthInsurance';
import HomeInsurance from './pages/HomePages/Insurance/HomeInsurance/HomeInsurance';
import VehicleInsurance from './pages/HomePages/Insurance/VehicleInsurance/VehicleInsurance';
import Demo from './pages/Demo'
import Leads from './pages/HomePages/LeadsForm/Leads';
 
// ----------------------------------------------------------------------
 
export default function App() {
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  useNavigate();
 
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <ToastContainer />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />
        <Route path="/achievements" element={<Achievement />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/help-centre" element={<HelpCentre />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/signup" element={<UserSignup />} />
        <Route path="/health-insurance" element={<HealthInsurance />} />
        <Route path="/home-insurance" element={<HomeInsurance />} />
        <Route path="/vehicle-insurance" element={<VehicleInsurance />} />
        <Route path="/pick" element={<Demo />} />
        <Route path="/Leads" element={<Leads />} />
        {/* Authenticated Routes */}
        {token && user?.role ? (
          user?.role === 'admin' ? (
            <Route path="/*" element={<Routers />} />
          ) : user?.role === 'user' ? (
            <Route path="/*" element={<UserRoutes />} />
          ) : null
        ) : (
          <>
            <Route path="/login-admin" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/login-admin" />} />
          </>
        )}
        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}