import React, { useEffect, useState } from 'react'
import '../Contact/Contact.css'
import '../Home.css'
import { useFormik } from "formik";
import * as yup from "yup";
import { FaYoutube, FaInstagram, FaFacebookF, FaLinkedinIn, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt,FaHeartbeat, FaCar, FaHome, FaBriefcase, FaPlane } from "react-icons/fa";
import { GiLifeInTheBalance } from "react-icons/gi";
import { Link } from 'react-router-dom';
import { MdPolicy } from "react-icons/md";
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';
import { apiget, apipost } from '../../../service/api';


const Contact = () => {
    const userid = localStorage.getItem('user_id')
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [userIp, setUserIp] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Get user's IP address
    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setUserIp(data.ip));
    }, []);

    // Get user's location
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        }
    }, []);

    const validationSchema = yup.object({
        fullName: yup.string().required("fullName is required"),
        phone: yup.string().required("phone is required"),
        email: yup.string().email('Invalid email').required('Email is required'),
        message: yup.string()
    });
    const insuranceIcons = {
        'Life Insurance': <GiLifeInTheBalance className="insurance-icon" />,
        'Health Insurance': <FaHeartbeat className="insurance-icon" />,
        'Vehicle Insurance': <FaCar className="insurance-icon" />,
        'Property Insurance': <FaHome className="insurance-icon" />,
        'Business Insurance': <FaBriefcase className="insurance-icon" />,
        'Travel Insurance': <FaPlane className="insurance-icon" />
    };
    // -----------   initialValues
    const initialValues = {
        fullName: "",
        phone: "",
        email: "",
        message: "",
        createdBy: userid
    };
    const [policyTypes, setPolicyTypes] = useState([]);
    const sendOtp = async (phone) => {
        try {
            const response = await apipost('send-otp', { phone });
            if (response.status === 200) {
                setShowOtpInput(true);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const verifyOtp = async (phone, otpValue) => {
        try {
            const response = await apipost('verify-otp', { phone, otp: otpValue });
            return response.status === 200;
        } catch (error) {
            console.error('Error verifying OTP:', error);
            return false;
        }
    };

    const fileUpload = async (values) => {
        const data = new FormData();
        
        // Add form fields
        data.append("fullName", values.fullName);
        data.append("phone", values.phone);
        data.append("email", values.email);
        data.append("message", values.message);
        data.append("createdBy", values.createdBy);
        
        // Add additional data
        data.append("ipAddress", userIp);
        data.append("latitude", userLocation?.latitude);
        data.append("longitude", userLocation?.longitude);
        data.append("submissionDateTime", new Date().toISOString());

        const result = await apipost(`enquiry/add`, data);

        if (result && result.status === 200) {
            formik.resetForm();
            setShowOtpInput(false);
            setOtp('');
        }
    }
    const fetchPolicyType = async () => {
        try {
            const result = await apiget(`policy-types/list`);
            if (result && result.status === 200) {
                setPolicyTypes(result?.data);
            } else {
                throw new Error('Failed to fetch policy types');
            }
        } catch (error) {
            console.error('Error fetching policy types:', error);
        }
    };
    useEffect(()=>{
        fetchPolicyType();
    },[]
    )
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (!showOtpInput) {
                // First submit - send OTP
                await sendOtp(values.phone);
            } else {
                // OTP verification
                setIsSubmitting(true);
                const isOtpValid = await verifyOtp(values.phone, otp);
                if (isOtpValid) {
                    await fileUpload(values);
                } else {
                    alert('Invalid OTP. Please try again.');
                }
                setIsSubmitting(false);
            }
        },
    });


    return (<>
        <Navbar />
        <div className="contact_touch">
            <div className='container'>
           
                <div className="insurance_types_block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Life Insurance']}
                                    <h4>Life Insurance</h4>
                                    <p>Secure your family's financial future with comprehensive life insurance coverage that provides protection and peace of mind.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Health Insurance']}
                                    <h4>Health Insurance</h4>
                                    <p>Get quality healthcare coverage for you and your family with plans that cover medical expenses, hospitalization, and more.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Vehicle Insurance']}
                                    <h4>Vehicle Insurance</h4>
                                    <p>Protect your vehicles with comprehensive auto insurance that covers accidents, damages, and third-party liabilities.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Property Insurance']}
                                    <h4>Property Insurance</h4>
                                    <p>Safeguard your home and property with coverage against natural disasters, theft, and other unforeseen damages.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Business Insurance']}
                                    <h4>Business Insurance</h4>
                                    <p>Comprehensive coverage for your business assets, liability protection, and employee benefits to keep operations running smoothly.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="insurance_type_card">
                                    {insuranceIcons['Travel Insurance']}
                                    <h4>Travel Insurance</h4>
                                    <p>Travel worry-free with insurance that covers medical emergencies, trip cancellations, lost baggage, and more.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <h3>Get In Touch</h3>
            </div>
        </div>

        <div className="contact_input_box">
            <div className="container">
                <div className="contact_input_box_bac">
                    <form className="row g-3 cont_mt" onSubmit={formik.handleSubmit}>
                        <div className="col-md-5">
                            <label htmlFor="fullName" className="form-label">Full Name
                                <input type="text" className="form-control" id="fullName" value={formik.values.fullName}
                                    onChange={formik.handleChange} name="fullName" />
                            </label>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="phone" className="form-label">Phone <i style={{color: "#c9c9c9", FontSize:"13px" }} >Only Phone number without +91,'0'</i>
                                <input type="text" className="form-control" id="phone" value={formik.values.phone}
                                    onChange={formik.handleChange} name="phone" />
                            </label>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="emailAddress" className="form-label">Email Address
                                <input type="email" className="form-control" id="email" value={formik.values.email}
                                    onChange={formik.handleChange} name="email" />
                            </label>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="typeofInsurance" className="form-label">Type of Insurance
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <MdPolicy />
                                    </span>
                                    <select
                                        className="form-select"
                                        aria-label="Type of Insurance"
                                        value={formik.values.insurance}
                                        onChange={formik.handleChange}
                                        name="insurance"
                                    >
                                        <option value="" disabled>Type of Insurance</option>
                                        {policyTypes &&
                                            policyTypes.length > 0 &&
                                            policyTypes.map((item, index) => (
                                                <option key={index} value={item.policyTypes}>
                                                    {item.policyTypes}
                                                </option>
                                            ))}
                                    </select>
                                    {formik.touched.insurance && formik.errors.insurance ? (
                                        <div className="error">{formik.errors.insurance}</div>
                                    ) : null}
                                </div>
                            </label>
                        </div>

                        <div className="col-md-10">
                            <label htmlFor="message" className="form-label">Message
                                <textarea className="form-control" id="message" value={formik.values.message}
                                    onChange={formik.handleChange} name="message" rows="3" />
                            </label>
                        </div>

                        {showOtpInput && (
                            <div className="col-md-5">
                                <label htmlFor="otp" className="form-label">Enter OTP
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        placeholder="Enter OTP sent to your phone"
                                    />
                                </label>
                            </div>
                        )}
                    </form>
                    <div className="contact_button">
                        <Link 
                            onClick={formik.handleSubmit}
                            className={isSubmitting ? 'disabled' : ''}
                        >
                            {showOtpInput ? 'Verify OTP' : 'Get In Touch'} <i className="fa fa-arrow-right" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>




        <div className="contact_corporate_office">
            <div className="container">
                <div className="cont_last">
                    <div className="contact_last_icen">
                        <Link to=""><FaPhoneAlt /></Link>
                        <h6>Call us toll free (24*7)</h6>
                        <p>+91 9314234442</p>
                    </div>
                    <div className="contact_last_icen">
                        <Link to=""><FaEnvelope /></Link>
                        <h6>Email us at</h6>
                        <p>advisor@vikasmahipal.com</p>
                    </div>
                    {/* <div className="contact_last_icen">
                        <Link to=""><FaEnvelope /></Link>
                        <h6>SMS Liberate</h6>
                        <p>561616</p>
                    </div> */}
                    <div className="contact_last_icen">
                        <Link to=""><FaMapMarkerAlt /></Link>
                        <h6>Corporate Office</h6>
                        <p>Roshan Lal Mahipal, L-102, Mahima Elite, Swej Farm, New Sanganer Road, Jaipur, Rajasthan, 302019</p>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </>
    )
}

export default Contact